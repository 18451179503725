import ContentSlideShow from '../../ContentSlideShow';
import SingleCard from '../ContentCard/SingleCard';
import React from 'react';
import CustomSliderItem from '../../ContentSlideShow/CustomSliderItem';
import InformationalCard from '../InformationalCard';
import InformationalVerticalCard from '../InformationalVerticalCard';
import P2Featurette from '../P2Featurette';
import TabbedCard from '../TabbedCard';

const ContentCardSlideShow = ({ content, type, CTA }) => {
  const tabs =
    type === 'tabbed-section'
      ? content.map((item) => ({
          value: item.title,
          label: item.title,
        }))
      : null;
  return (
    <ContentSlideShow type={type} tabs={tabs} CTA={CTA} length={content.length} forwardSx={{ pt: ['4rem', '5.6rem'] }}>
      {content.map((item, i) => {
        switch (item.mediaType) {
          case 'informational':
            return <InformationalCard key={item.id} contentCard={item} isEven={i % 2 === 0} />;
          case 'informational-vertical':
            return <InformationalVerticalCard key={item.id} contentCard={item} />;
          case 'P2 Featurette':
            return <P2Featurette key={item.id} contentCard={item} />;
          case 'tabbed-section':
            return <TabbedCard key={item.id} contentCard={item} index={i} />;
          default:
            return (
              <CustomSliderItem key={item.id}>
                <SingleCard contentCard={item} single={true} />
              </CustomSliderItem>
            );
        }
      })}
    </ContentSlideShow>
  );
};

export default ContentCardSlideShow;
