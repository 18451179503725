import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { Box, Text, Grid, Flex, ThemeUIStyleObject } from 'theme-ui';
import ImgLoader from '~/components/Generic/ImgLoader';
import BadgeHighlight from '../FeaturedCard/BadgeHighlight';
import CTAButtonWithWrapper from '~/components/Generic/CTAButtonWithWrapper';

type Props = {
  contentCard: Queries.ContentCardFragmentFragment;
  isEven: boolean;
};

const InformationalCard = ({ contentCard, isEven }: Props) => {
  const imageDesktop = contentCard.images?.[0];
  const imageMobile = contentCard.imagesMobile?.[0];
  const {
    description,
    title,
    titleGradient,
    highlights,
    highlightImage,
    titleDesktop,
    titleMobile,
    backgroundColor,
    cta,
  } = contentCard;
  const titleStyle: ThemeUIStyleObject = {
    m: 0,
    fontWeight: '450',
    fontSize: ['2.4rem', '3.6rem'],
    textTransform: 'uppercase',
    lineHeight: '100%',
    letterSpacing: '-0.02em',
    backgroundImage: titleGradient ? 'linear-gradient(93.95deg, #FE6A00 0%, #244076 47.42%);' : null,
    backgroundClip: titleGradient ? 'text' : null,
    WebkitBackgroundClip: title ? 'text' : null,
    textFillColor: titleGradient ? 'transparent' : 'black',
    WebkitTextFillColor: titleGradient ? 'transparent' : 'black',
  };
  return (
    <Grid
      sx={{
        m: 'auto',
        width: '100%',
        height: '100%',
        position: 'relative',
        gridTemplateColumns: ['1fr', isEven ? 'minmax(63%, 3fr) 2fr' : '2fr minmax(63%, 3fr)'],
        rowGap: ['2.4rem', 0],
        columnGap: [0, '5.6rem'],
        pb: ['2.4rem', '0'],
        backgroundColor: backgroundColor,
      }}
    >
      <Box
        sx={{
          aspectRatio: ['1/1', '17/10'],
          width: '100%',
          minHeight: ['0', '300px'],
          maxHeight: [null, '496px'],
          position: 'relative',
          overflow: 'hidden',
          isolation: 'isolate',
          order: [0, isEven ? 0 : 1],
        }}
      >
        {imageDesktop && (
          <ImgLoader
            image={imageDesktop}
            forwardSx={{
              display: ['none', 'block'],
              position: 'absolute',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              top: 0,
              left: 0,
            }}
          />
        )}
        {imageMobile || imageDesktop ? (
          <ImgLoader
            image={imageMobile ?? imageDesktop ?? null}
            forwardSx={{
              display: ['block', 'none'],
              position: 'absolute',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              top: 0,
              left: 0,
            }}
          />
        ) : null}
      </Box>
      <Box
        sx={{
          placeSelf: 'center',
          maxWidth: ['none', '45.6rem'],
          width: ['100%', 'auto'],
          px: ['2.4rem', 0],
          mr: [0, isEven ? '5.6rem' : 0],
          ml: [0, isEven ? 0 : '5.6rem'],
          textAlign: ['center', 'left'],
        }}
      >
        {highlights && highlights?.length > 0 && (
          <Flex sx={{ gap: '1.2rem', mb: ['1.6rem', null, '2.4rem '] }}>
            {highlights.map((highlight, index) => (
              <BadgeHighlight key={`${highlight}-${index}`} text={highlight} />
            ))}
          </Flex>
        )}
        {highlightImage && (
          <Box sx={{ width: '6.4rem', display: ['none', 'block'], mb: ['1.6rem', null, '2.4rem '] }}>
            {/* @ts-ignore */}
            <GatsbyImage image={getImage(highlightImage)} alt={highlightImage.title ?? ''} />
          </Box>
        )}

        {!titleDesktop && !titleMobile && title && (
          <Box
            sx={{
              ...titleStyle,
            }}
          >
            <Text
              as="h2"
              sx={{
                'p, h1, h2, h3, h4, h5, h6': {
                  m: 0,
                  ...titleStyle,
                },
              }}
            >
              {title}
            </Text>
          </Box>
        )}
        {titleDesktop && (
          <Box
            sx={{
              display: ['none', 'block'],
              ...titleStyle,
            }}
          >
            <Text
              as="h2"
              sx={{
                'p, h1, h2, h3, h4, h5, h6': {
                  m: 0,
                  ...titleStyle,
                },
              }}
              dangerouslySetInnerHTML={{ __html: titleDesktop?.childMarkdownRemark?.html ?? '' }}
            />
          </Box>
        )}
        {titleMobile && (
          <Box
            sx={{
              display: ['block', 'none'],
              ...titleStyle,
            }}
          >
            <Text
              as="h2"
              sx={{
                'p, h1, h2, h3, h4, h5, h6': {
                  m: 0,
                  ...titleStyle,
                },
              }}
              dangerouslySetInnerHTML={{ __html: titleMobile?.childMarkdownRemark?.html ?? '' }}
            />
          </Box>
        )}
        {description && (
          <Text
            sx={{
              'p, li, h1, h2, h3, h4, h5, h6': {
                m: 0,
                mt: ['1.6rem', '2.4rem'],
                fontSize: ['1.7rem', '2rem'],
                lineHeight: [1.2, 1.4],
              },
              li: {
                m: 0,
                textAlign: 'left',
              },
            }}
            dangerouslySetInnerHTML={{ __html: description.childMarkdownRemark?.html ?? '' }}
          />
        )}
        {!!cta && (
          // @ts-ignore
          <CTAButtonWithWrapper callToAction={cta} forwardSx={{ display: 'block', mt: '2.4rem', mx: ['auto', 0] }} />
        )}
      </Box>
    </Grid>
  );
};

export default InformationalCard;
