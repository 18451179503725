import React from 'react';
import { ThemeUIStyleObject, Grid, Box, Text, Flex } from 'theme-ui';
import CTAButtonWithWrapper from '~/components/Generic/CTAButtonWithWrapper';
import ImgLoader from '~/components/Generic/ImgLoader';

type Props = {
  contentCard: Queries.ContentCardFragmentFragment;
};

const InformationalVerticalCard = ({ contentCard }: Props) => {
  const imageDesktop = contentCard.images?.[0];
  const imageMobile = contentCard.imagesMobile?.[0];
  const { description, title, titleDesktop, titleMobile, backgroundColor, cta } = contentCard;
  const titleStyle: ThemeUIStyleObject = {
    m: 0,
    fontWeight: '500',
    fontSize: ['2.4rem', '2.8rem'],
    textTransform: 'uppercase',
    lineHeight: '100%',
    letterSpacing: '-0.02em',
    textAlign: ['center', 'left'],
  };

  return (
    <Grid
      sx={{
        m: 'auto',
        width: '100%',
        height: '100%',
        position: 'relative',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto 1fr',
        rowGap: '2.4rem',
        pb: ['3.2rem', '2.4rem'],
        backgroundColor: backgroundColor,
      }}
    >
      <Box
        sx={{
          aspectRatio: ['69/50', '224/279'],
          width: '100%',
          position: 'relative',
          isolation: 'isolate',
        }}
      >
        {imageDesktop && (
          <ImgLoader
            image={imageDesktop}
            forwardSx={{
              display: ['none', 'block'],
              position: 'absolute',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              top: 0,
              left: 0,
            }}
          />
        )}
        {imageMobile || imageDesktop ? (
          <ImgLoader
            image={imageMobile ?? imageDesktop ?? null}
            forwardSx={{
              display: ['block', 'none'],
              position: 'absolute',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              top: 0,
              left: 0,
            }}
          />
        ) : null}
      </Box>
      <Flex
        sx={{
          placeSelf: ['center', 'start'],
          px: '2.4rem',
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          {!titleDesktop && !titleMobile && title && (
            <Box>
              <Text
                as="h3"
                sx={{
                  'p, h1, h2, h3, h4, h5, h6': {
                    m: 0,
                    ...titleStyle,
                  },
                }}
              >
                {title}
              </Text>
            </Box>
          )}
          {titleDesktop && (
            <Box
              sx={{
                display: ['none', 'block'],
                ...titleStyle,
              }}
            >
              <Text
                as="h2"
                sx={{
                  'p, h1, h2, h3, h4, h5, h6': {
                    m: 0,
                    ...titleStyle,
                  },
                }}
                dangerouslySetInnerHTML={{ __html: titleDesktop?.childMarkdownRemark?.html ?? '' }}
              />
            </Box>
          )}
          {titleMobile && (
            <Box
              sx={{
                display: ['block', 'none'],
                ...titleStyle,
              }}
            >
              <Text
                as="h2"
                sx={{
                  'p, h1, h2, h3, h4, h5, h6': {
                    m: 0,
                    ...titleStyle,
                  },
                }}
                dangerouslySetInnerHTML={{ __html: titleMobile?.childMarkdownRemark?.html ?? '' }}
              />
            </Box>
          )}
          {description && (
            <Text
              sx={{
                '>*': {
                  m: 0,
                  mt: '1.6rem',
                  fontSize: ['1.8rem', '2rem'],
                  lineHeight: [1.2, 1.4],
                  textAlign: ['center', 'left'],
                },
                ul: {
                  pl: '3.2rem',
                },
              }}
              dangerouslySetInnerHTML={{ __html: description.childMarkdownRemark?.html ?? '' }}
            />
          )}
        </Box>

        {!!cta && (
          // @ts-ignore
          <CTAButtonWithWrapper callToAction={cta} forwardSx={{ display: 'block', mt: '2.4rem', mx: ['auto', 0] }} />
        )}
      </Flex>
    </Grid>
  );
};

export default InformationalVerticalCard;
